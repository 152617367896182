import { PAGE_WP_TYPE } from '../contentTypes/page';
import { returnTemplate } from '../lib/_client';
import { returnStaticProps } from '../lib/_helpers';
import { getSupportStatusPageData } from '../lib/getSnowflakeData';
import Support from '../templates/Support';

export default function Template(props) {
  return returnTemplate(props, Support);
}

const termsToStrings = (blocks) => {
  const result: Record<string, string> = {};

  for (const block of blocks) {
    result[block.attributes.data['data-key']] =
      block.innerBlocks[0].attributes.innerContent;
  }

  return result;
};

export async function getStaticProps(oProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let props: null | Record<string, any> = null;
  const page = await returnStaticProps({
    ...oProps,
    slug: 'support-status',
    type: PAGE_WP_TYPE,
  });

  if (page.props?.construct) {
    const strings = termsToStrings(page.props.construct.blocks);
    const data = await returnStaticProps({
      ...oProps,
      query: getSupportStatusPageData,
      slug: 'support-status',
      type: PAGE_WP_TYPE,
    });

    props = data?.props ?? {};
    props.globals = page.props.globals || null;

    props.construct.productSupportStats.Chat.increment = strings.minutesKey;
    props.construct.productSupportStats.Call.increment = strings.percentageKey;
    props.construct.productSupportStats.Email.increment = strings.hoursKey;
    props.construct.productSupportStats.Touches.increment = strings.percentageKey;
    props.construct.productSupportStats.CSAT.increment = strings.percentageKey;
    props.construct.globalHrAdvisorStats.CSAT.increment = strings.percentageKey;
    props.construct.globalHrAdvisorStats.TFR.increment = strings.hoursKey;
    props.construct.globalHrAdvisorStats.TTR.increment = strings.daysKey;

    props.construct.productSupportStats.Chat.description = strings.liveChatTooltipKey;
    props.construct.productSupportStats.Call.description = strings.percentsLiveChatTooltipKey;
    props.construct.productSupportStats.Email.description = strings.emailTooltipKey;
    props.construct.productSupportStats.Touches.description = strings.percentFCRTooltipKey;
    props.construct.productSupportStats.CSAT.description = strings.percentsCSATTooltipKey;
    props.construct.globalHrAdvisorStats.CSAT.description = strings.percentsCSATTooltipKey;
    props.construct.globalHrAdvisorStats.TFR.description = strings.emailTooltipKey;
    props.construct.globalHrAdvisorStats.TTR.description = strings.timeToResolutionTooltipKey;
    props.construct.globalHrAdvisorStats.TTR.descriptionAsParagraph = true;

    props.construct.globalHrAdvisorStats.infoText = strings.globalAdvisorInfoText;
    props.construct.globalHrAdvisorStats.ctaText = strings.globalAdvisorCtaText;

    props.construct.strings = strings;
  }

  return props ? { props } : page;
}
